<template>
 <div>
      <!--- partie specialite  -->
<p id="concept"></p>
<div class="jumbotron jumbotron-fluid text-center" style="background-color:#EEEEEE;">
  <div class="container">
    <div class="row" >
      <div class="col-12">
        <h4 style="text-align: center;" >Une solution intuitive construite <br> au plus près de vos besoins</h4>
        <hr class="float-center" style="width: 15%;"/>
      </div>
      <div class="col-md-4 col-xs-12 text-left" > 
        <div class="card" style="background-color:#EEEEEE;">
          <img class="card-img-top mx-auto" src="../assets/gyn_medical.png" width="20%" style="margin-top: 1em; max-width: 100px; " alt="">
          <div class="card-body" style="text-align: center;">
            <h5>Gynécologie médicale</h5>
            <p>Disponible quand vous l’êtes et quand vous en avez le plus besoin </p>
          <p>
      This is a long text test.
      <a
        href
        v-tooltip
        title="<p>jjjg</p><p>jjjg</p><p>jjjg</p>"
      >hover me</a>
      When it is long enough, will it wrap?
    </p>
          </div>  
        </div>
      </div>
      <div class="col-md-4 col-xs-12 text-left"> 
        <div class="card" style="background-color:#EEEEEE;">
          <img class="card-img-top mx-auto" src="../assets/genecologie_ obstetrique.png" width="20%" style="margin-top: 1em; max-width: 100px; " alt="">
          <div class="card-body" style="text-align: center;">
            <h5>Génécologie Obstétrique</h5>
            <p>Grâce l’anonymisation 
              des données</p>
          </div>  
        </div>
      </div>
      <div class="col-md-4 col-xs-12 text-left"> 
        <div class="card" style="background-color:#EEEEEE;">
          <img class="card-img-top mx-auto" src="../assets/chirurgie_digestive.png" width="20%" style="margin-top: 1em; max-width: 100px; " alt="">
          <div class="card-body" style="text-align: center;">
            <h5>Chirurgie viscérale et digestive</h5>
            <p>Financé par la communauté, hors des conflits d’intérêts</p>
          </div>  
        </div>
      </div>
      <div class="col-md-4 col-xs-12 text-left"> 
        <div class="card" style="background-color:#EEEEEE;">
          <img class="card-img-top mx-auto" src="../assets/oncologie.png" width="20%" style="margin-top: 1em; max-width: 100px; " alt="">
          <div class="card-body" style="text-align: center;">
            <h5>Oncologie</h5>
            <p>Un outil, un objectif : vous accompagner dans votre pratique </p>
          </div>  
        </div>
      </div>
      <div class="col-md-4 col-xs-12 text-left"> 
        <div class="card" style="background-color:#EEEEEE;">
          <img class="card-img-top mx-auto" src="../assets/medecine_sante.png" width="20%" style="margin-top: 1em; max-width: 100px; " alt="">
          <div class="card-body" style="text-align: center;">
            <h5>Médecine et santé au travail </h5>
            <p>Développez des échanges transversaux pour améliorer la prise en charge patient</p>
          </div>  
        </div>
      </div>
      <div class="col-md-4 col-xs-12 text-left"> 
        <div class="card" style="background-color:#EEEEEE;">
          <img class="card-img-top mx-auto" src="../assets/pediatrie.png" width="20%" style="margin-top: 1em; max-width: 100px; " alt="">
          <div class="card-body" style="text-align: center;">
            <h5>Pédiatrie</h5>
            <p>À votre écoute, aujourd’hui comme demain</p>
          </div>  
        </div>
      </div>

      <div class="col-md-4 col-xs-12 text-left"> 
        <div class="card" style="background-color:#EEEEEE;">
          <img class="card-img-top mx-auto" src="../assets/anesthesie.png" width="20%" style="margin-top: 1em; max-width: 100px; " alt="">
          <div class="card-body" style="text-align: center;">
            <h5>Anesthésie réanimation</h5>
            <p>Un outil, un objectif : vous accompagner dans votre pratique </p>
          </div>  
        </div>
      </div>
      <div class="col-md-4 col-xs-12 text-left"> 
        <div class="card" style="background-color:#EEEEEE;">
          <img class="card-img-top mx-auto" src="../assets/radiologie.png" width="20%" style="margin-top: 1em; max-width: 100px; " alt="">
          <div class="card-body" style="text-align: center;">
            <h5>Radiologie et imagerie médicale</h5>
            <p>Développez des échanges transversaux pour améliorer la prise en charge patient</p>
          </div>  
        </div>
      </div>
      <div class="col-md-4 col-xs-12 text-left"> 
        <div class="card" style="background-color:#EEEEEE;">
          <img class="card-img-top mx-auto" src="../assets/hepato_gastro.png" width="20%" style="margin-top: 1em; max-width: 100px; " alt="">
          <div class="card-body" style="text-align: center;">
            <h5>Hépato-gastro-entérologie</h5>
            <p>À votre écoute, aujourd’hui comme demain</p>
          </div>  
        </div>
      </div>
    </div>
  </div>
</div>
 </div>
</template>

<script>


export default {
  name: 'specialite',
  
  props: {
    msg: String,
    urlimage : String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
      border: 0px solid rgba(0, 0, 0, 0.125);
}
hr {
    display: block;
    unicode-bidi: isolate;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    overflow: hidden;
    border-style: inset;
    border-width: 1px;
    border: 0;
    border-top: 2px solid #0033CC;
    border-radius: 5px;
}
</style>
