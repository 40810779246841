<template>
  <div class="container">
    
    <HelloWorld msg="Contact "  description=" <p>Avez-vous une question à poser ou une remarque à formuler à Medical Innovation technology ? <br>N'hésitez pas à remplir le formulaire suivant ou nous joindre par e-mail : med.innovation.technology@gmail.com.</p>" urlimage="contact.png" />
    <contact />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloComposant.vue'
import contact from '@/components/contactcomposant.vue'

export default {
  name: 'Contact',
  components: {
    HelloWorld,
    contact    
  }
}
</script>
