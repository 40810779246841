import { render, staticRenderFns } from "./specialite.vue?vue&type=template&id=22b777af&scoped=true&"
import script from "./specialite.vue?vue&type=script&lang=js&"
export * from "./specialite.vue?vue&type=script&lang=js&"
import style0 from "./specialite.vue?vue&type=style&index=0&id=22b777af&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22b777af",
  null
  
)

export default component.exports