<template>
  <div>    
    <HelloWorld msg="Spécialites médicales" urlimage="specialite_medecine.png"/>
    <specialite msg="Spécialites médicales" urlimage="logo_01.png"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloComposant.vue'
import Specialite from '../components/specialite.vue'

export default {
  name: 'SM',
  components: {
    HelloWorld,
    Specialite
  }
}
</script>

