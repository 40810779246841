<template>
  <div class="container">
    
    <HelloWorld msg="Contenu de la formation" urlimage="contenu_formation.png"/>
    <contenuformation />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloComposant.vue'
import Contenuformation from '../components/contenuformation.vue'

export default {
  name: 'CF',
  components: {
    HelloWorld,
    Contenuformation
  }
}
</script>
