<template>
<div class="container">
  
  <div class="row">
        <div class="col-lg-8 col-md-8 col-12">
          <div class="form-head">
            <!-- Contact Form -->
            <div class="row">
  <b-form @submit="onSubmit" @reset="onReset" v-if="show">
    
    <b-form-group
      id="input-group-1"
      label="Adresse Email:"
      label-for="input-1"
      description="Ne partagerons jamais votre e-mail"
      label-cols="4" label-cols-lg="2" label-size="sm"
    >
      <b-form-input
        id="input-1"
        v-model="form.email"
        type="email"
        placeholder="Email"
        required
      ></b-form-input>
    </b-form-group>
    

    <b-form-group id="input-group-2" label="Votre nom" label-for="input-2"
    label-cols="4" label-cols-lg="2" label-size="sm">
      <b-form-input
        id="input-2"
        v-model="form.nom"
        placeholder="Nom"
        required
      ></b-form-input>
    </b-form-group>
  
  
    <b-form-group id="input-group-3" label="Votre prénom" label-for="input-3"
    label-cols="4" label-cols-lg="2" label-size="sm">
      <b-form-input
        id="input-3"
        v-model="form.prenom"
        placeholder="Prénom"
        required
      ></b-form-input>
    </b-form-group>

    <b-form-group id="input-group-4" label="Votre pays" label-for="input-4"
    label-cols="4" label-cols-lg="2" label-size="sm">
      <b-form-input
        id="input-4"
        v-model="form.pays"
        placeholder="Pays"
        required
      ></b-form-input>
    </b-form-group>
 
    <b-form-group id="input-group-5" label="Spécialité:" label-for="input-5"
    label-cols="4" label-cols-lg="2" label-size="sm">
      <b-form-select
        id="input-5"
        v-model="form.specialite"
        :options="specialites"
        required
      ></b-form-select>
    </b-form-group>

    <b-form-group id="input-group-6" label="Message" label-for="input-6"
    label-cols="4" label-cols-lg="2" label-size="sm">
      <b-form-textarea
          id="input-6"
          v-model="form.message"
          placeholder="Message..."
          rows="3"
          max-rows="6"
  ></b-form-textarea>
    </b-form-group>
   
    
    <b-form-group id="input-group-7" 
    label-cols="4" label-cols-lg="2" label-size="sm">           
    <b-button type="submit" variant="primary">Submit</b-button>
    <b-button type="reset" variant="danger">Reset</b-button>
   </b-form-group>
                
  </b-form>
            </div>
  </div>
        </div>
        <div class="col-lg-4 col-md-4 col-12">
          <div class="contact-right">
                          
            <!-- Contact-Info -->
            <div class="contact-info">
              <div class="icon"><i class="fa fa-envelope"></i></div>
              <h3>Email:</h3>
              <p><a href="mailto:med.innovation.technology@gmail.com">med.innovation.technology@gmail.com</a></p>
              
            </div>
            <!-- Contact-Info -->
          </div>
        </div>
      </div>
  
</div>
</template>

<script>
import axios from 'axios'
import router from '../router/index.js';


//https://vuejsexamples.com/simple-alert-for-vue-js/

export default {
  
  data() {
    return {
      form: {
        email: '',
        nom: '',
        prenom: '',
        pays: '',
        specialite: null,
        message: ''
      },
      specialites: [{ text: 'Sélectionner une', value: null }, 'Médecine générale','Anesthésie réanimation',
'Gynécologie médicale','Neurochirurgie','Maladie infectieuse et tropicale','Psychiatrie','Chirurgie maxillo-faciale','Biologie médicale',
'Médecine physique et de réadaptation','Pédiatrie',
'Urologie','Médecine nucléaire','Gériatrie',
'Orthopédie dentofaciale', 'Oto-rhino-laryngologie – chirurgie cervico faciale',
'Radiologie et imagerie médicale','Chirurgie vasculaire',
'Médecine et santé au travail','Génécologie Obstétrique',
'Chirurgie viscérale et digestive','Médecine intensive – réanimation',
'Médecine cardiovasculaire','Rhumatologie',
'Chirurgie thoracique et cardiovasculaire','Chirurgie plastique, reconstructrice et esthétique',
'Endocrinologie - diabétologie-nutrition'],
      show: true
    }
  },
  methods: {
    resetForm: function(){
      this.form.email = ''
      this.form.nom = ''
      this.form.prenom = ''
      this.form.pays = ''
      this.form.specialite = ''
      this.form.message =''
     
    },
    onSubmit(event) {
      event.preventDefault()
 
        console.log(this.form.nom)
        console.log(this.form.email)
        let formData = new FormData();
      
        formData.append('email', this.form.email)
        formData.append('nom', this.form.nom)
        formData.append('prenom', this.form.prenom)
        formData.append('pays', this.form.pays)
        formData.append('specialite', this.form.specialite)
        formData.append('message', this.form.message)
   
        axios({
            method: 'post',
            url: 'dao/insert.php',
            data: formData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(function (response) {
            //handle success
            console.log(response)
            //this.contacts.push(contact)
            this.$alert("Email est enregistrée.");
            router.go(-1)
              
        })
        .catch(function (response) {
            //handle error
            console.log(response)
        });


////
      //alert(JSON.stringify(this.form))
    },
    onReset(event) {
      event.preventDefault()
      // Reset our form values
      this.form.email = ''
      this.form.nom = ''
      this.form.prenom = ''
      this.form.pays = ''
      this.form.specialite = ''
      this.form.message =''
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }

  }
}

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
