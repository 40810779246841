<template>
 <div>
      <!--- partie contenu de formation  -->

<div class="jumbotron jumbotron-fluid text-center" style="background-color:#EEEEEE;">
  <div class="container">
    <div class="row" >
      <div class="col-12">
        <h4 style="text-align: center;" >Une solution intuitive construite <br> au plus près de vos besoins</h4>
        <hr class="float-center" style="width: 15%;"/>
      </div>
      <div class="col-md-4 col-xs-12 text-left" > 
        <div class="card" style="background-color:#EEEEEE;">
          <img class="card-img-top mx-auto" src="../assets/support.png" width="20%" style="margin-top: 1em; max-width: 100px; " alt="">
          <div class="card-body" style="text-align: center;">
            <h5>support du cours</h5>
            <p>Disponible quand vous l’êtes et quand vous en avez le plus besoin </p>
          </div>  
        </div>
      </div>
      <div class="col-md-4 col-xs-12 text-left"> 
        <div class="card" style="background-color:#EEEEEE;">
          <img class="card-img-top mx-auto" src="../assets/exam_blanc.png" width="20%" style="margin-top: 1em; max-width: 100px; " alt="">
          <div class="card-body" style="text-align: center;">
            <h5>Examens blancs</h5>
            <p>Grâce l’anonymisation 
              des données</p>
          </div>  
        </div>
      </div>
      <div class="col-md-4 col-xs-12 text-left"> 
        <div class="card" style="background-color:#EEEEEE;">
          <img class="card-img-top mx-auto" src="../assets/tenir.png" width="20%" style="margin-top: 1em; max-width: 100px; " alt="">
          <div class="card-body" style="text-align: center;">
            <h5>conduites à tenir</h5>
            <p>Financé par la communauté, hors des conflits d’intérêts</p>
          </div>  
        </div>
      </div>
      <div class="col-md-4 col-xs-12 text-left"> 
        <div class="card" style="background-color:#EEEEEE;">
          <img class="card-img-top mx-auto" src="../assets/examen_fontamemtals.png" width="20%" style="margin-top: 1em; max-width: 100px; " alt="">
          <div class="card-body" style="text-align: center;">
            <h5>Correction des épreuves fondamentales précédentes </h5>
            <p>Un outil, un objectif : vous accompagner dans votre pratique </p>
          </div>  
        </div>
      </div>
      <div class="col-md-4 col-xs-12 text-left"> 
        <div class="card" style="background-color:#EEEEEE;">
          <img class="card-img-top mx-auto" src="../assets/examen_pratique.png" width="20%" style="margin-top: 1em; max-width: 100px; " alt="">
          <div class="card-body" style="text-align: center;">
            <h5>Correction des épreuves pratiques précédentes </h5>
            <p>Un outil, un objectif : vous accompagner dans votre pratique </p>
          </div>  
        </div>
      </div>
      <div class="col-md-4 col-xs-12 text-left"> 
        <div class="card" style="background-color:#EEEEEE;">
          <img class="card-img-top mx-auto" src="../assets/personalisee.png" width="20%" style="margin-top: 1em; max-width: 100px; " alt="">
          <div class="card-body" style="text-align: center;">
            <h5>Prise en charge personnalisée </h5>
            <p>Développez des échanges transversaux pour améliorer la prise en charge patient</p>
          </div>  
        </div>
      </div>
    </div>
  </div>
</div>
 </div>
</template>

<script>
export default {
  name: 'contenu',
  props: {
    msg: String,
    urlimage : String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
      border: 0px solid rgba(0, 0, 0, 0.125);
}

hr {
    display: block;
    unicode-bidi: isolate;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    overflow: hidden;
    border-style: inset;
    border-width: 1px;
    border: 0;
    border-top: 2px solid #0033CC;
    border-radius: 5px;
}
</style>
