<template>
  <div class="container">
    
    <HelloWorld msg="Préparation aux EVC" urlimage="evc.png" description="Les épreuves de vérification des connaissances (EVC) s’adressent aux praticiens des professions de médecin, chirurgien-dentiste, sage-femme et pharmacien titulaires d’un diplôme obtenu en dehors de l’Union européenne et permettant l’exercice de leur profession dans le pays d’obtention. Elles constituent la première étape de la procédure d’autorisation d’exercice (PAE)"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloComposant.vue'

export default {
  name: 'SM',
  components: {
    HelloWorld
  }
}
</script>
